<template>
  <div class="appointment-list-view">
    <app-header icon="calendar-alt" title="Consultas Agendadas"></app-header>

    <section>
      <div class="card">
        <header class="card-header">
          <div class="card-header-content">
            <div class="row">
              <div class="col-3">
                <app-input
                  v-model="searchDoctorTerm"
                  label="Filtrar por Médico(a)"
                  placeholder="Nome, e-mail ou CPF/CNPJ"
                  icon="search"
                >
                </app-input>
              </div>
              <div class="col-3">
                <app-input
                  v-model="searchPatientTerm"
                  label="Filtrar por Paciente"
                  placeholder="Nome, e-mail ou CPF"
                  icon="search"
                >
                </app-input>
              </div>

              <div class="col-2">
                <b-field label="Status">
                  <b-select
                    v-model="status"
                    placeholder="Selecionar..."
                    expanded
                  >
                    <option :value="'ALL'" :key="0">Todos</option>
                    <option :value="'CANCELED'" :key="1">Canceladas</option>
                    <option :value="'PERFORMED'" :key="2">Realizadas</option>
                    <option :value="'TO_PERFORM'" :key="3">A realizar</option>
                  </b-select>
                </b-field>
              </div>

              <div class="col-3">
                <b-field label="Status de pagamento">
                  <b-select
                    class="select-input"
                    v-model="filterPaymentStatus"
                    placeholder="Status de pagamento"
                  >
                    <option value="ALL">Todos</option>
                    <option value="CANCELED">Cancelada</option>
                    <option value="VOUCHER_100">Voucher 100%</option>
                    <option value="EXTERNAL">Externo</option>
                    <option value="WAITING">Aguardando</option>
                    <option value="CONFIRMED">Confirmado</option>
                  </b-select>
                </b-field>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-3">
                <b-field class="period-filter" label="Período">
                  <b-select
                    @input="onPeriodChange()"
                    v-model="periodFilter"
                    expanded
                  >
                    <option
                      v-for="period in periods"
                      :key="period.id"
                      :value="period.id"
                      :disabled="period.disabled"
                    >
                      {{ period.name }}
                    </option>
                  </b-select>
                </b-field>
              </div>

              <div v-if="showCustomPeriod" class="col-3">
                <b-field label="Selecionar período">
                  <b-datepicker
                    icon="calendar"
                    placeholder="Escolha as datas"
                    @input="onRangeChange"
                    range
                  >
                  </b-datepicker>
                </b-field>
              </div>

              <div class="col-3">
                <b-field label="Especialidade">
                  <b-select
                    class="select-input"
                    v-model="searchSpecialtyId"
                    placeholder="Especialidade"
                    name="searchSpecialtyId"
                  >
                    <option :value="null">Todas</option>
                    <option
                      v-for="specialty in specialties"
                      :key="specialty.id"
                      :value="specialty.id"
                    >
                      {{ specialty.name }}
                    </option>
                  </b-select>
                </b-field>
              </div>
              <div class="col-3">
                <b-field label="Subespecialidade">
                  <b-select
                    class="select-input"
                    v-model="searchSubspecialtyId"
                    placeholder="Subespecialidade"
                    name="searchSpecialtyId"
                  >
                    <option :value="null">Todas</option>
                    <option
                      v-for="subspecialty in subspecialties"
                      :key="subspecialty.id"
                      :value="subspecialty.id"
                    >
                      {{ subspecialty.name }}
                    </option>
                  </b-select>
                </b-field>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-3">
                <app-input
                  label="Resultados por página"
                  v-model="perPage"
                  type="number"
                  min="1"
                ></app-input>
              </div>
            </div>
          </div>
          <div class="card-header-buttons">
            <div>
              <b-tooltip label="Exportar XLS">
                <b-button
                  class="m-1"
                  @click="exportSchedules()"
                  type="is-dark"
                  size="is-small"
                  icon-left="file-excel"
                  :disabled="isExporting"
                  :loading="isExporting"
                >
                  Exportar
                </b-button>
              </b-tooltip>
            </div>
          </div>
        </header>
        <div class="card-content">
          <b-table
            @page-change="onPageChange"
            :current-page.sync="page"
            :loading="isLoading"
            :per-page="perPage"
            :total="total"
            :data="data"
            paginated
            backend-pagination
            aria-next-label="Próxima Página"
            aria-previous-label="Página Anterior"
            aria-page-label="Página"
            aria-current-label="Página Atual"
          >
            <b-table-column v-slot="props" field="value" label="Médico">
              {{ props.row.doctor.name || '-' }}
            </b-table-column>
            <b-table-column v-slot="props" field="value" label="Paciente">
              {{ props.row.patient.name }}
            </b-table-column>
            <b-table-column v-slot="props" field="value" label="Início">
              {{ props.row.start | moment('DD/MM/YYYY HH:mm') }}
            </b-table-column>
            <b-table-column v-slot="props" field="value" label="Valor">
              R$
              {{ toMoney(props.row.value) }}
            </b-table-column>
            <b-table-column v-slot="props" field="value" label="Taxa">
              R$
              {{ toMoney(props.row.value_fee) }}
            </b-table-column>
            <b-table-column v-slot="props" field="status" label="Status">
              <b-tag :type="`is-${props.row.schedule_status.type}`">
                {{ props.row.schedule_status.label }}
              </b-tag>
            </b-table-column>

            <b-table-column
              v-slot="props"
              field="payment_status"
              label="Status Pgto"
            >
              <div v-if="!props.row.payment_status">-</div>
              <div v-if="props.row.payment_status">
                <b-tag :type="`is-${props.row.payment_status.type}`">
                  {{ props.row.payment_status.label }}
                </b-tag>
              </div>
            </b-table-column>
            <b-table-column
              v-slot="props"
              label="Opções"
              cell-class="width-100"
            >
              <router-link
                :to="{
                  name: 'schedules.save',
                  params: { id: props.row.id },
                }"
              >
                <b-tooltip label="Visualizar" class="m-1">
                  <b-button
                    type="is-secondary"
                    size="is-small"
                    icon-right="eye"
                  />
                </b-tooltip>
              </router-link>
            </b-table-column>
            <template slot="footer">
              <div class="has-text-right">
                <div>
                  <b>Valores conforme o filtro:</b>
                  <br />
                </div>

                <div>
                  <b>Total:</b> R$
                  {{ schedule_values.search_total }}
                </div>

                <div>
                  Valor médio das consultas: R${{ schedule_values.average }}
                </div>

                <div>
                  <b>Total na página:</b> R$
                  {{ schedule_values.paginated_total }}
                </div>

                <div>
                  <b
                    >Total de consultas:
                    {{ schedule_values.total_of_schedules }}</b
                  >
                </div>
              </div>
            </template>
            <template slot="empty">
              <section class="section">
                <div class="content has-text-grey has-text-centered">
                  <p>
                    <b-icon icon="frown" size="is-large"></b-icon>
                  </p>
                  <p>Nenhum registro encontrado.</p>
                </div>
              </section>
            </template>
          </b-table>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import * as moment from 'moment';
import debounce from 'lodash/debounce';
import PERIODS from '@/constants/periods.constant';

import DashboardService from '@/services/dashboard.service';
import SchedulesService from '@/services/schedules.service';
import SpecialtiesService from '@/services/specialties.service.js';
import SubspecialtiesService from '@/services/subspecialties.service.js';

export default {
  data: () => ({
    periods: PERIODS,
    isLoading: true,
    data: [],
    total: 0,
    page: 1,
    perPage: 10,
    dates: [],
    showCustomPeriod: false,
    searchDoctorTerm: null,
    searchPatientTerm: null,
    searchStart: null,
    searchEnd: null,
    periodFilter: 6,
    filterPaymentStatus: null,
    enableSeconds: false,
    hourFormat: '24',
    status: 'ALL',
    specialties: [],
    subspecialties: [],
    searchSpecialtyId: null,
    searchSubspecialtyId: null,
    searchDoctor: null,
    searchPatient: null,
    isExporting: false,
    schedule_values: {},
  }),
  watch: {
    searchDoctorTerm: debounce(function () {
      this.page = 1;
      this.load();
    }, 400),
    searchPatientTerm: debounce(function () {
      this.page = 1;
      this.load();
    }, 400),
    searchStart() {
      this.page = 1;
      this.load();
    },
    searchEnd() {
      this.page = 1;
      this.load();
    },
    searchSpecialtyId() {
      this.page = 1;
      this.load();
      this.loadSubspecialties();
    },
    searchSubspecialtyId() {
      this.page = 1;
      this.load();
    },
    status() {
      this.page = 1;
      this.load();
    },
    perPage() {
      if (this.perPage > this.data.length) this.load();
    },
    filterPaymentStatus() {
      this.page = 1;
      this.load();
    },
  },
  methods: {
    loadScheduleValues() {
      const params = {};

      if (this.dates.length == 2) {
        params['dates'] = `${this.dates[0]},${this.dates[1]}`;
      }

      if (this.searchSpecialtyId) {
        params['specialty'] = this.searchSpecialtyId;
      }

      if (this.searchSubspecialtyId) {
        params['subspecialty'] = this.searchSubspecialtyId;
      }

      if (this.searchDoctorTerm) {
        params['doctor_info'] = this.searchDoctorTerm;
      }

      if (this.searchPatientTerm) {
        params['patient_info'] = this.searchPatientTerm;
      }

      if (this.type >= 0) {
        params['type'] = this.type;
      }

      if (this.searchProcedure) {
        params['procedure'] = this.searchProcedure;
      }

      if (this.status) {
        params['status'] = this.status;
      }

      if (this.filterPaymentStatus) {
        params['payment_status'] = this.filterPaymentStatus;
      }

      DashboardService.getScheduleValues({
        ...params,
      }).then(({ data: values }) => {
        let paginatedValues = 0;
        this.data.forEach((data) => (paginatedValues += Number(data.value)));
        this.schedule_values = {
          ...values,
          paginated_total: paginatedValues.toFixed(2),
        };
      });
    },
    loadSpecialties() {
      this.isLoading = true;
      SpecialtiesService.getAll()
        .then(({ data }) => (this.specialties = data))
        .finally(() => (this.isLoading = false));
    },
    loadSubspecialties() {
      this.isLoading = true;
      if (this.searchSpecialtyId) {
        SubspecialtiesService.getAll({ specialty_id: this.searchSpecialtyId })
          .then(({ data }) => {
            this.subspecialties = data;
          })
          .finally(() => (this.isLoading = false));
      }
    },
    clearFilters() {
      this.searchDoctorTerm = null;
      this.searchPatientTerm = null;
      this.searchStart = null;
      this.searchEnd = null;
    },
    load() {
      this.isLoading = true;

      const params = {
        page: this.page,
        perPage: this.perPage,
        doctor: this.searchDoctor,
        patient: this.searchPatient,
        searchSpecialtyId:
          this.searchSpecialtyId != null ? this.searchSpecialtyId : '',
        paymentStatus: this.paymentStatus,
      };

      if (this.status) {
        params['filter[status]'] = this.status;
      }

      if (this.searchDoctorTerm) {
        params['filter[doctor_info]'] = this.searchDoctorTerm;
      }

      if (this.searchPatientTerm) {
        params['filter[patient_info]'] = this.searchPatientTerm;
      }

      if (this.dates.length == 2) {
        params[
          'filter[start_end_between]'
        ] = `${this.dates[0]},${this.dates[1]}`;
      }

      if (this.searchSpecialtyId) {
        params['filter[doctor.by_specialty]'] =
          this.searchSpecialtyId != null ? this.searchSpecialtyId : '';
      }

      if (this.searchSubspecialtyId) {
        params['filter[doctor.by_subspecialty]'] =
          this.searchSubspecialtyId != null ? this.searchSubspecialtyId : '';
      }

      if (this.filterPaymentStatus) {
        params['filter[payment_status]'] =
          this.filterPaymentStatus != null ? this.filterPaymentStatus : '';
      }

      SchedulesService.get({ ...params })
        .then(({ data }) => {
          const { total, data: items, current_page } = data;
          this.total = total;
          this.data = Object.values(items);
          this.page = current_page;
        })
        .finally(() => {
          this.loadScheduleValues();
          this.isLoading = false;
        });
    },
    onClickDelete(id) {
      this.$buefy.dialog.confirm({
        title: 'Excluindo Consulta',
        message:
          'Tem certeza que deseja <b>excluir</b> esta consulta? Essa ação não pode ser desfeita.',
        cancelText: 'Cancelar',
        confirmText: 'Excluir Consulta',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => this.delete(id),
      });
    },
    delete(id) {
      SchedulesService.delete(id)
        .then(() => {
          this.$buefy.snackbar.open('Consulta excluída com sucesso.');
          this.load();
        })
        .catch(({ response }) => {
          if (typeof response != 'undefined' && response.status != 200) {
            this.$buefy.snackbar.open(
              response.message || 'Erro ao tentar excluir a Consulta.',
              'error',
              4000
            );
          }
        });
    },
    onRangeChange(value) {
      this.dates = value.map((date) => moment(date).format('YYYY-MM-DD'));
      this.load();
    },
    onPageChange(page) {
      this.page = page;
      this.load();
    },
    exportSchedules() {
      this.isExporting = true;

      let filters = {
        term_patient: this.searchPatientTerm || '',
        term_doctor: this.searchDoctorTerm || '',
        start_greater: this.searchStart || '',
        end_less: this.searchEnd || '',
        specialty_id: this.searchSpecialtyId || '',
      };

      SchedulesService.export({ ...filters })
        .then(({ data }) => {
          const fileName = data.split('/')[data.split('/').length - 1];
          const fileLink = document.createElement('a');

          fileLink.target = '_blank';
          fileLink.href = data;
          fileLink.setAttribute('download', fileName);
          fileLink.setAttribute('style', 'display:none;');

          document.body.appendChild(fileLink);
          fileLink.click();

          fileLink.remove();
        })
        .catch(({ response }) => {
          const message =
            response.data.message || 'Erro ao realizar exportação.';
          this.$buefy.snackbar.open(message);
        })
        .finally(() => (this.isExporting = false));
    },
    toMoney(value) {
      return parseFloat(value).toFixed(2).replace(',', '.').replace('.', ',');
    },
    onPeriodChange() {
      const period = this.periods.find((item) => item.id == this.periodFilter);
      this.page = 1;
      this.showCustomPeriod = false;

      if (period.clear) {
        this.dates = [];
        this.load();
      } else if (period.custom) {
        this.showCustomPeriod = true;
        this.dates = null;
      } else {
        const { start, end } = period;

        this.dates = [
          moment(start).format('YYYY-MM-DD'),
          moment(end).format('YYYY-MM-DD'),
        ];

        this.load();
      }
    },
  },
  mounted() {
    this.load();
    this.loadSpecialties();
  },
};
</script>
<style lang="scss">
.select-input .select,
.select-input .select select {
  width: 100%;
}
</style>
